<template>
  <div class="p-20 bg-white rounded">
    <a-collapse :activeKey="activeKey" :bordered="false" class="bg-white">
      <a-collapse-panel key="1" :showArrow="false" disabled>
        <!-- <span slot="extra" style="color: #333">
          <span @click.stop="setActiveKey('')" v-if="activeKey[0] === '1'"
            >收起</span
          >
          <span @click.stop="setActiveKey('1')" v-else>编辑</span>
        </span> -->
        <div slot="header" class="inline-flex items-center" style="color: #333">
          <div style="width: 120px" class="mr-20">头像</div>
          <div style="width: 48px; height: 48px">
            <img
              v-if="info.Logo"
              class="w-full h-full object-contain"
              :src="info.Logo.url"
              alt=""
            />
          </div>
        </div>
        <div class="p-20 bg-f9">
          <a-form-model
            :model="model"
            :rules="rules"
            :colon="false"
            labelAlign="left"
          >
            <a-form-model-item
              prop="Logo"
              :labelCol="{ span: 6 }"
              :wrapperCol="{ span: 15 }"
            >
              <div slot="label" class="h-40 flex items-center">
                <span>头像</span>
              </div>
              <picture-upload
                :scale="{ w: 1, h: 1 }"
                v-model="model.Logo"
                :width="338"
                :height="160"
                accept=".png,.jpg,.jpeg"
                :size="5"
              ></picture-upload>
            </a-form-model-item>
          </a-form-model>
          <div class="text-right">
            <a-space>
              <a-button type="primary" ghost @click="setActiveKey('')"
                >取消</a-button
              >
              <a-button type="primary" @click="saveLogo">保存</a-button>
            </a-space>
          </div>
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="2" :showArrow="false" disabled>
        <!-- <span slot="extra" style="color: #333">
          <span @click.stop="setActiveKey('')" v-if="activeKey[0] === '2'"
            >收起</span
          >
          <span @click.stop="setActiveKey('2')" v-else>编辑</span>
        </span> -->
        <div slot="header" class="inline-flex" style="color: #333">
          <div style="width: 120px" class="mr-20">个人资料</div>
          <div>{{ info.username || "无" }}</div>
        </div>
        <div class="p-20 bg-f9"></div>
      </a-collapse-panel>
      <a-collapse-panel key="3" :showArrow="false" disabled
        ><span slot="extra" style="color: #333">
          <span @click.stop="setActiveKey('')" v-if="activeKey[0] === '3'"
            >收起</span
          >
          <span @click.stop="setActiveKey('3')" v-else>编辑</span>
        </span>
        <div slot="header" class="inline-flex" style="color: #333">
          <div style="width: 120px" class="mr-20">登录手机</div>
          <div>{{ info.phone || "无" }}</div>
        </div>
        <div class="p-20 bg-f9">
          <a-form-model
            :model="model"
            :rules="rules"
            :colon="false"
            labelAlign="left"
            ref="phone"
          >
            <a-form-model-item
              prop="phone"
              :labelCol="{ span: 6 }"
              :wrapperCol="{ span: 9 }"
            >
              <div slot="label" class="h-40 flex items-center">
                <span>需绑定的手机</span>
              </div>
              <a-input
                :maxLength="11"
                size="large"
                v-model="model.phone"
                placeholder="请输入手机号码"
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item
              prop="phoneCode"
              :labelCol="{ span: 6 }"
              :wrapperCol="{ span: 9 }"
            >
              <div slot="label" class="h-40 flex items-center">
                <span>验证码</span>
              </div>
              <a-input
                :maxLength="6"
                size="large"
                v-model="model.phoneCode"
                placeholder="请输入验证码"
              >
                <count-down
                  slot="suffix"
                  text="发送验证码"
                  :count="60"
                  :send="sendBindPhoneCode"
                >
                </count-down>
              </a-input>
            </a-form-model-item>
          </a-form-model>
          <div class="text-right">
            <a-space>
              <a-button type="primary" ghost @click="setActiveKey('')"
                >取消</a-button
              >
              <a-button type="primary" @click="savePhone">保存</a-button>
            </a-space>
          </div>
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="4" :showArrow="false" disabled>
        <span slot="extra" style="color: #333">
          <span @click.stop="setActiveKey('')" v-if="activeKey[0] === '4'"
            >收起</span
          >
          <span @click.stop="setActiveKey('4')" v-else>编辑</span>
        </span>
        <div slot="header" class="inline-flex" style="color: #333">
          <div style="width: 120px" class="mr-20">登录邮箱</div>
          <div>{{ info.email || "无" }}</div>
        </div>
        <div class="p-20 bg-f9">
          <a-form-model
            :model="model"
            :rules="rules"
            :colon="false"
            labelAlign="left"
            ref="email"
          >
            <a-form-model-item
              prop="email"
              :labelCol="{ span: 6 }"
              :wrapperCol="{ span: 9 }"
            >
              <div slot="label" class="h-40 flex items-center">
                <span>需绑定的邮箱</span>
              </div>
              <a-input
                size="large"
                v-model="model.email"
                placeholder="请输入邮箱"
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item
              prop="emailCode"
              :labelCol="{ span: 6 }"
              :wrapperCol="{ span: 9 }"
            >
              <div slot="label" class="h-40 flex items-center">
                <span>验证码</span>
              </div>
              <a-input
                :maxLength="6"
                size="large"
                v-model="model.emailCode"
                placeholder="请输入验证码"
              >
                <count-down
                  slot="suffix"
                  text="发送验证码"
                  :count="60"
                  :send="sendBindingCode"
                >
                </count-down>
              </a-input>
            </a-form-model-item>
          </a-form-model>
          <div class="text-right">
            <a-space>
              <a-button type="primary" ghost @click="setActiveKey('')"
                >取消</a-button
              >
              <a-button type="primary" @click="saveEmail">保存</a-button>
            </a-space>
          </div>
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="5" :showArrow="false" disabled>
        <span slot="extra" style="color: #333">
          <span @click.stop="setActiveKey('')" v-if="activeKey[0] === '5'"
            >收起</span
          >
          <span @click.stop="setActiveKey('5')" v-else>编辑</span>
        </span>
        <div slot="header" class="inline-flex" style="color: #333">
          <div style="width: 120px" class="mr-20">登录密码</div>
          <div>********</div>
        </div>
        <div class="p-20 bg-f9">
          <a-form-model
            :model="model"
            :rules="rules"
            :colon="false"
            labelAlign="left"
            ref="password"
          >
            <a-form-model-item
              prop="old_password"
              :labelCol="{ span: 6 }"
              :wrapperCol="{ span: 9 }"
            >
              <div slot="label" class="h-40 flex items-center">
                <span>当前密码</span>
              </div>
              <a-input-password
                size="large"
                v-model="model.old_password"
                placeholder="请输入"
              >
              </a-input-password>
            </a-form-model-item>
            <a-form-model-item
              prop="password"
              :labelCol="{ span: 6 }"
              :wrapperCol="{ span: 9 }"
            >
              <div slot="label" class="h-40 flex items-center">
                <span>新的密码</span>
              </div>
              <a-input-password
                size="large"
                v-model="model.password"
                placeholder="请输入"
              >
              </a-input-password>
            </a-form-model-item>
            <a-form-model-item
              prop="confirm_password"
              :labelCol="{ span: 6 }"
              :wrapperCol="{ span: 9 }"
            >
              <div slot="label" class="h-40 flex items-center">
                <span>确认新密码</span>
              </div>
              <a-input-password
                size="large"
                v-model="model.confirm_password"
                placeholder="请输入"
              >
              </a-input-password>
            </a-form-model-item>
          </a-form-model>
          <div class="text-right">
            <a-space>
              <a-button type="primary" ghost @click="setActiveKey('')"
                >取消</a-button
              >
              <a-button type="primary" @click="updatePassword">保存</a-button>
            </a-space>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { transformAttachment } from "@/utils/transform";
import {
  updateUserInfo,
  bindPhone,
  bindEmail,
  sendBindPhoneCode,
  sendBindingCode,
  setPassword,
} from "@/api/user";
import { validatePassword } from "@/utils/validator";
export default {
  data() {
    return {
      activeKey: [],
      model: {},
      info: {},
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    rules() {
      return {
        phone: [
          { required: true, message: "请输入", trigger: ["change", "blur"] },
        ],
        phoneCode: [
          { required: true, message: "请输入", trigger: ["change", "blur"] },
        ],
        email: [
          { required: true, message: "请输入", trigger: ["change", "blur"] },
        ],
        emailCode: [
          { required: true, message: "请输入", trigger: ["change", "blur"] },
        ],
        old_password: [
          { min: 8, message: "密码长度至少8位", trigger: "blur" },
          { required: true, message: "请输入", trigger: ["change", "blur"] },
        ],
        password: [
          { min: 8, max: 16, message: "密码长度8-16位", trigger: "blur" },
          { required: true, message: "请输入", trigger: ["change", "blur"] },
          {
            validator: validatePassword,
            message: "密码应包含数字、字母、字符中的两种或两种以上",
            trigger: "blur",
          },
        ],
        confirm_password: [
          { min: 8, max: 16, message: "密码长度8-16位", trigger: "blur" },
          { required: true, message: "请输入", trigger: ["change", "blur"] },
          {
            validator: this.validatePassword,
            message: "两次输入的密码需一致",
            trigger: "blur",
          },
        ],
      };
    },
  },
  methods: {
    validatePassword(rule, value, callback) {
      if (this.model.confirm_password === this.model.password) {
        callback();
      } else {
        callback(new Error());
      }
    },
    setActiveKey(key) {
      if (!key) {
        this.model = {};
      }
      this.activeKey = [key];
    },
    saveLogo() {
      const temp = { ...this.userInfo };
      temp.Profile = this.model.Logo;
      this.updateUserInfo(temp);
    },
    savePhone() {
      this.$refs.phone.validate((valid) => {
        if (valid) {
          const temp = {
            phone: this.model.phone,
            code: this.model.phoneCode,
          };

          bindPhone(temp)
            .then(() => {
              this.$message.success("绑定成功");
              this.$store.dispatch("getUserInfo");
              this.setActiveKey("");
            })
            .catch(({ msg }) => {
              this.$message.error(msg);
            });
        }
      });
    },
    saveEmail() {
      this.$refs.email.validate((valid) => {
        if (valid) {
          const temp = {
            email: this.model.email,
            code: this.model.emailCode,
          };

          bindEmail(temp)
            .then(() => {
              this.$message.success("绑定成功");
              this.$store.dispatch("getUserInfo");
              this.setActiveKey("");
            })
            .catch(({ msg }) => {
              this.$message.error(msg);
            });
        }
      });
    },
    updateUserInfo(data) {
      updateUserInfo(data)
        .then(() => {
          this.$message.success("更新成功");
          this.$store.dispatch("getUserInfo");
          this.setActiveKey("");
        })
        .catch(() => {
          this.$message.error("更新用户信息失败");
        });
    },
    sendBindPhoneCode() {
      if (!this.model.phone) {
        this.$message.error("请输入需要绑定的手机号");
        return Promise.reject();
      }
      const temp = {
        phone: this.model.phone,
      };
      return sendBindPhoneCode(temp).then((res) => {
        return Promise.resolve(res);
      });
    },
    sendBindingCode() {
      if (!this.model.email) {
        this.$message.error("请输入需要绑定的邮箱");
        return Promise.reject();
      }
      const temp = {
        email: this.model.email,
      };
      return sendBindingCode(temp).then((res) => {
        return Promise.resolve(res);
      });
    },
    updatePassword() {
      this.$refs.password.validate((valid) => {
        if (valid) {
          const temp = {
            old_password: this.model.old_password,
            password: this.model.password,
          };
          setPassword(temp)
            .then(() => {
              this.$message.success("更改密码成功");
              this.$store.dispatch("getUserInfo");
              this.setActiveKey("");
            })
            .catch(({ msg }) => {
              this.$message.error(msg);
            });
        }
      });
    },
  },
  watch: {
    userInfo: {
      handler(val) {
        const temp = { ...val };
        temp.Logo = transformAttachment(temp.Profile);

        this.info = temp;
      },
      immediate: true,
    },
  },
};
</script>